import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import './CookieConsent.css';
import { checkAndUpdateLanguage } from '../i18n/i18n';

const cookies = new Cookies();

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!cookies.get('cookieConsent')) {
      setShowBanner(true);
    }

    const language = checkAndUpdateLanguage(i18n.language);
    console.log('CookieConsent component rendered. Current language:', language);

    const handleLanguageChange = () => {
      console.log('Language change event detected in CookieConsent');
      checkAndUpdateLanguage(i18n.language);
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, [i18n.language]);

  const handleAcceptAll = () => {
    cookies.set('cookieConsent', 'true', { path: '/', maxAge: 60 * 60 * 24 * 365 });
    setShowBanner(false);
  };

  const handleAcceptEssential = () => {
    cookies.set('cookieConsent', 'essential', { path: '/', maxAge: 60 * 60 * 24 * 365 });
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="cookie-banner">
          <p className="cookie-text">
            {t('This site uses cookies to offer you a better browsing experience. Find out more on')} &nbsp;
            <a href="https://www.hongkong.ai/privacy-policy" className="cookie-link">{t('How we use cookies')}</a>&nbsp;, &nbsp;
            <a href="https://www.hongkong.ai/TermsOfUse" className="cookie-link">{t('Terms of use')}</a>.
          </p>
          &nbsp;&nbsp;
          <button onClick={handleAcceptAll} className="cookie-button">{t('Accept all cookies')}</button>
          <button onClick={handleAcceptEssential} className="cookie-button">{t('Accept only essential cookies')}</button>
        </div>
      )}
    </>
  );
};

export default CookieConsent;