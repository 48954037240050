// tools.js
import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Container, Pagination, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Tools.css';
import Helmet from 'react-helmet';
import CardComponent from './ToolsComponent';
import newsData from './ToolsData.json'; // Import the news data

const Tools = () => {
  const newsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState('All'); // State to store the current filter

  // Function to handle the filter change
  const handleFilterChange = useCallback((event) => {
    setCurrentFilter(event.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  }, []);

  // Filter the news articles according to the current filter
  const filteredNewsArticles = useMemo(() => {
    return currentFilter === 'All'
      ? newsData
      : newsData.filter((article) => article.topic === currentFilter);
  }, [currentFilter]);

  const pageCount = Math.ceil(filteredNewsArticles.length / newsPerPage);
  const currentNews = filteredNewsArticles.slice(
    (currentPage - 1) * newsPerPage,
    currentPage * newsPerPage
  );

  const handlePageClick = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const paginationItems = useMemo(() => {
    let items = [];
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageClick(number)}
          aria-label={`Go to page ${number}`}
        >
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }, [pageCount, currentPage, handlePageClick]);

  return (
    <>
      <Helmet>
        {/* ... Helmet content ... */}
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content="HONGKONG.AI - Your AI Hub in Hong Kong" />
        <meta property="og:description"
          content="Stay up-to-date with the latest technology news, covering AI, robotics, and more.
    " />
        <meta property="og:image" content="https://www.hongkong.ai/HK_1920x1080.jpg" />
        <link rel="apple-touch-icon" href="https://www.hongkong.ai/HK_1920x1080.jpg" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        <title>HONGKONG.AI - Latest Tech News</title>

      </Helmet>

      <Container>
        <div className="separator" />
        <h1 className="text-left">Tools</h1>
        <div className="separator" />

        <Row className="align-items-center justify-content-end">
          <Col xs={12} sm={4} md={3} lg={2} className="mb-2">
            <Form>
              <Form.Group controlId="topicFilter">
                <Form.Label className="visually-hidden">Filter by topic</Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  value={currentFilter}
                  onChange={handleFilterChange}
                  className="form-select-sm custom-select" // Add the custom-select class here
                // Remove the inline style for backgroundImage if added previously
                >
                  <option value="All">All Topics</option>
                  {/* Generate options dynamically based on topics in newsData */}
                  {[...new Set(newsData.map((article) => article.topic))].map((topic) => (
                    <option key={topic} value={topic}>
                      {topic}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Col>
        </Row>
<br></br>
        <Row>
          {currentNews.map((article) => (
            <Col lg={3} md={6} sm={12} className="mb-4 d-flex" key={article.title}>
              <CardComponent
                image={article.image}
                date={article.date}
                title={article.title}
                text={article.text}
                url={article.url}
                alt={`${article.title} - ${article.text.substring(0, 100)}...`}
                topic={article.topic} // Pass the topic here
              />
            </Col>
          ))}
        </Row>

        <Pagination className="justify-content-center my-pagination">{paginationItems}</Pagination>
        <div className="separator" />
      </Container>
    </>
  );
};

export default Tools;