import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Container, Pagination } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AI4Good.css';
import { Helmet } from 'react-helmet';
import CardComponent from './CardComponent';
import { checkAndUpdateLanguage } from '../i18n/i18n';

// Import all AI4Good data files
import AI4GoodDataEn from '../data/AI4Good_Data_en.json';
import AI4GoodDataSc from '../data/AI4Good_Data_sc.json';
import AI4GoodDataTc from '../data/AI4Good_Data_tc.json';

const AI4Good = () => {
  const { t, i18n } = useTranslation();
  const [AI4GoodData, setAI4GoodData] = useState([]);
  const [error, setError] = useState(null);
  const cardsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  console.log('AI4Good component rendered. Current language:', i18n.language);

  const loadAI4GoodData = useCallback(() => {
    try {
      const language = checkAndUpdateLanguage(i18n.language);
      let data;

      console.log('Loading AI4Good data for language:', language);

      switch (language) {
        case 'en':
          data = AI4GoodDataEn;
          break;
        case 'sc':
          data = AI4GoodDataSc;
          break;
        case 'tc':
          data = AI4GoodDataTc;
          break;
        default:
          data = AI4GoodDataEn; // fallback to English
      }

      console.log('Data loaded:', data);
      setAI4GoodData(data);
      setError(null);
    } catch (err) {
      console.error('Error loading AI4Good data:', err);
      setError(err);
    }
  }, [i18n.language]);

  useEffect(() => {
    console.log('useEffect triggered');
    loadAI4GoodData();

    const handleLanguageChange = () => {
      console.log('Language change event detected');
      loadAI4GoodData();
    };

    window.addEventListener('languageChanged', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChanged', handleLanguageChange);
    };
  }, [i18n.language, loadAI4GoodData]);

  const pageCount = Math.ceil(AI4GoodData.length / cardsPerPage);
  const currentCards = AI4GoodData.slice(
    (currentPage - 1) * cardsPerPage,
    currentPage * cardsPerPage
  );

  const handlePageClick = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const paginationItems = useMemo(() => {
    let items = [];
    for (let number = 1; number <= pageCount; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageClick(number)}
          aria-label={`Go to page ${number}`}
        >
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }, [pageCount, currentPage, handlePageClick]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content="HONGKONG.AI - Your AI Hub in Hong Kong" />
        <meta property="og:description"
          content="Explore AI Transformation for Social Good projects in Hong Kong." />
        <meta property="og:image" content="https://www.hongkong.ai/HK_1920x1080.jpg" />
        <link rel="apple-touch-icon" href="https://www.hongkong.ai/HK_1920x1080.jpg" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        <title>HONGKONG.AI - AI Transformation for Social Good</title>
      </Helmet>

      <Container>
        <div className="separator" />
        <h1>{t('AI Transformation for Social Good')}</h1>
        <div className="separator" />

        <Row>
          {currentCards.map((card, index) => (
            <Col lg={6} md={6} sm={12} className="mb-4 d-flex" key={index}>
              <CardComponent
                image={card.image}
                title={card.title}
                text={card.text}
                alt={`${card.title} - ${card.text.substring(0, 100)}...`}
              />
            </Col>
          ))}
        </Row>

        <Pagination className="justify-content-center my-pagination">{paginationItems}</Pagination>
        <div className="separator" />
      </Container>
    </>
  );
};

export default AI4Good;